// React, hooks, router
import React from 'react';
import { Routes, Route } from "react-router-dom";

// MUI composants
import { Box } from '@mui/material';
import { Link } from '@mui/material';

// MUI autres
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Interne
import Accueil from 'pages/Accueil/Accueil'
import FootShooterPrivacy from 'pages/FootShooter/Privacy'
import FootShooterGame from 'pages/FootShooter/Game'
import './App.css';

// Divers
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

///////////////////////////// FIN IMPORTS /////////////////////////////

const theme = createTheme({
  palette: {
    primary: {
      light: '#efefef',
      main: '#bdbdbd',
      dark: '#8d8d8d',
      contrastText: '#000000',
    },
    secondary: {
      light: '#5c5c5c',
      main: '#333333',
      dark: '#0c0c0c',
      contrastText: '#ffffff',
    },
  }
});

function App() {
    
  return (
      <ThemeProvider theme={theme}>
        <Box sx={{ p: 5, backgroundImage: `url(/images/HomeBackground.png)`, minHeight:900 }}>
          <Box sx={{ p: 2}} textAlign="center">
            <Link href="/" color="inherit">
              <img src="/images/TubeDeCodeLandscape.png" width="80%" alt="Tube de code logo" />
            </Link>
          </Box>
          <Routes>
            <Route path="/" element={<Accueil />} />
            <Route path="/footshooter/privacy" element={<FootShooterPrivacy />} />
            <Route path="/footshooter/Game" element={<FootShooterGame />} />
          </Routes>
        </Box>
      </ThemeProvider>
  );
}

export default App;
