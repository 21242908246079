// React, hooks, router
import React from 'react';

// MUI composants
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link } from '@mui/material';

// MUI icones

// Pour APIs

// Interne

// Divers

///////////////////////////// FIN IMPORTS /////////////////////////////


export default function Game() {
  
  return (
    <Box sx={{ p: 3, backgroundColor: "rgba(200, 200, 200, .8)" }}>
      <header>
        <h1>Foot Shooter</h1>
      </header>
      <Box sx={{ display: 'flex', flexDirection: { xs: "column", md:"row"} }}>
        
        <Box sx={{ mr:3, display: 'flex', flexDirection: 'column' }}>
          <Link href="https://play.google.com/store/apps/details?id=com.TubeDeCode.FootShooter" color="inherit">
            <Box sx={{minWidth:"100px", maxWidth: { xs: "200px", md:"500px"} }}>
              <img src="/images/FootShooterLogoMini.png" height="auto" width="100%" alt="Foot shooter logo" />
            </Box>
            <Box sx={{minWidth:"100px", maxWidth: { xs: "200px", md:"300px"} }}>
              <img src="/images/logo-google-play.webp" height="auto" width="100%" alt="Fiche Play Store" />
            </Box>
          </Link>
        </Box>
        <Box>
          <Typography variant="body1">
            <h2>Découvrez "Foot Shooter"</h2>

            <h3>Le jeu où votre agilité et votre rapidité sont vos meilleurs atouts pour devenir un champion.</h3>

            <p>
              <h4>Fonctionnalités :</h4>
              <ul>
                <li><u>Mécanique Innovante</u> : Utilisez un canon pour lancer des ballons avec précision. Visez juste et triomphez !</li>
                <br/>
                <li><u>Obstacles Dynamiques</u> : Certains niveaux vous réservent des surprises avec des obstacles à détruire. Préparez-vous à user de stratégie et d'adresse pour atteindre votre but.</li>
                <br/>
                <li><u>Jeu Intuitif</u> : Des commandes simples pour une prise en main rapide, permettant à tous de s'immerger dans le jeu sans délai.</li>
              </ul>
            </p>

            <p>
              <h4>Pourquoi Foot Shooter ?</h4>
              Parce que c'est plus qu'un jeu de football. C'est une épreuve de rapidité, d'adresse, et de stratégie. Que vous soyez fan de football ou à la recherche d'un jeu captivant, "Foot Shooter" vous offre une expérience unique et excitante.
            </p>

            <p>
              <h4>Relevez le Défi :</h4>
              Devenez le maître du canon, détruisez les obstacles, et marquez des buts spectaculaires pour gravir les échelons du succès. Votre aventure commence maintenant !
            </p>   

            <p>
              <Box sx={{width: { xs: "270px", md:"560px", xl:"1000px"}, height: { xs: "152px", md:"315px", xl:"562px"} }} >
                <iframe style={{display: "block", width: "100%", height: "100%"}} src="https://www.youtube.com/embed/D5k56c4yCFY?si=XsUkGwFA7kJmOQ29" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
              </Box>
            </p>
            <p>
              <Box sx={{width: { xs: "270px", md:"560px", xl:"1000px"} }} >
                <img src="/images/capture_1.png" height="auto" width="100%" alt="Capture 1" />
              </Box>
            </p>
            <p>
              <Box sx={{width: { xs: "270px", md:"560px", xl:"1000px"} }} >
                <img src="/images/capture_2.png" height="auto" width="100%" alt="Capture 2" />
              </Box>
            </p>
            <p>
              <Box sx={{width: { xs: "270px", md:"560px", xl:"1000px"} }} >
                <img src="/images/capture_3.png" height="auto" width="100%" alt="Capture 3" />
              </Box>
            </p>
            <p>
              <Box sx={{width: { xs: "270px", md:"560px", xl:"1000px"} }} >
                <img src="/images/capture_4.png" height="auto" width="100%" alt="Capture 4" />
              </Box>
            </p>
            <p>
              <Box sx={{width: { xs: "270px", md:"560px", xl:"1000px"} }} >
                <img src="/images/capture_5.png" height="auto" width="100%" alt="Capture 5" />
              </Box>
            </p>
            <p>
              <Box sx={{width: { xs: "270px", md:"560px", xl:"1000px"} }} >
                <img src="/images/capture_6.png" height="auto" width="100%" alt="Capture 6" />
              </Box>
            </p>
            <p>
              <Link href="https://play.google.com/store/apps/details?id=com.TubeDeCode.FootShooter" color="inherit">
                Téléchargez "Foot Shooter" et que le meilleur tireur gagne !
                <Box style={{minWidth:"100px", maxWidth:"300px"}}>
                  <img src="/images/logo-google-play.webp" height="auto" width="100%" alt="Fiche Play Store" />
                </Box>
              </Link>
            </p>
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}