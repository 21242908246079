// React, hooks, router
import React, { Component } from 'react'

// MUI composants
import Box from '@mui/material/Box';
import { Link } from '@mui/material';

///////////////////////////// FIN IMPORTS /////////////////////////////

export class Accueil extends Component {
  render() {
    const styleHighlight = {
      color: "#0062CD",
      fontWeight: "bold"
    };
    return (
      <Box sx={{ p: 3, backgroundColor: "rgba(200, 200, 200, .8)" }}>
        <header>
          <h1>Développeur de jeux mobiles</h1>
        </header>
        <section id="game-gallery">
            <h2>Galerie de Jeux</h2>
            <Box>
              
              <h3>Foot shooter</h3>
              <Box style={{minWidth:"100px", maxWidth:"200px"}}>
                <Link href="/footshooter/Game" color="inherit">
                  <img src="/images/FootShooterLogoMini.png" height="auto" width="100%" alt="Foot shooter logo" />
                </Link>
              </Box>
              <Box style={{minWidth:"100px", maxWidth:"200px"}}>
                <Link href="https://play.google.com/store/apps/details?id=com.TubeDeCode.FootShooter" color="inherit">
                  <img src="/images/logo-google-play.webp" height="auto" width="100%" alt="Fiche Play Store" />
                </Link>
              </Box>
            </Box>
        </section>
        <section id="about-me">
            <h2>À propos de moi</h2>
            <p>Bonjour,</p>
            <p>Je suis un <span style={styleHighlight}>ingénieur en informatique spécialisé en développement</span>, avec <span style={styleHighlight}>18 ans d'expérience</span> enrichissante dans ce domaine dynamique.
            Ma carrière a débuté avec une solide expertise en <span style={styleHighlight}>Java</span>, domaine dans lequel j'ai évolué pendant 10 ans, avant de me tourner vers le <span style={styleHighlight}>C#</span> pour les 8 années suivantes.
            Au cours de ma carrière, j'ai acquis une compétence approfondie dans l'utilisation de technologies clés telles que <span style={styleHighlight}>Docker, Elastic Search</span>, ainsi que les bases de données <span style={styleHighlight}>Oracle et MariaDB</span>.</p>
            <p>Un des projets marquants de ma carrière a été le développement du backend du site web <Link href="https://www.dealburn.fr" color="inherit" fontWeight="bold">Dealburn.fr</Link>. Ce que je chéris le plus dans mon métier, c'est la possibilité de me confronter à des <span style={styleHighlight}>problèmes ardus</span> et de trouver des solutions innovantes.
            J'ai également une passion pour l'<span style={styleHighlight}>entraide</span> et le partage de mes connaissances avec les autres.</p>
            <p>Récemment, j'ai entrepris un voyage passionnant dans le monde du <span style={styleHighlight}>développement de jeux vidéo</span>.
            Cette nouvelle aventure représente pour moi non seulement un défi stimulant, mais aussi l'occasion d'explorer de nouvelles facettes de ma passion pour la technologie et la créativité. J'aspire à m'épanouir davantage dans ce domaine, en mettant à profit mon expérience en développement et ma capacité à résoudre des problèmes complexes pour créer des jeux captivants et enrichissants.</p>
            <p>Je suis enthousiaste à l'idée de continuer à évoluer et à contribuer au monde de la technologie et du développement de jeux vidéo.</p>

        </section>
        <footer>
            <section id="social-media">
            </section>
            <section id="contact-form">
                <form>
                </form>
            </section>
        </footer>
      </Box>
      )
  }
}

export default Accueil;